import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { useOutgoingInvoices } from './useOutgoingInvoices'
import { capitalize, currency } from '@/utils/filter'
import { getRoute, displayPaymentStatus } from '@/utils/utils'

import i18n from '@/libs/i18n'
import store from '@/store'
import router from '@/router'
import useAPI from '@/utils/useAPI'

import ModalOutgoingInvoice from '@/components/prompt/OutgoingInvoice'
import outgoingInvoice from '../../../store/outgoingInvoice/outgoingInvoice'

export const mixinOutgoingInvoices = {
  components: {
    ModalOutgoingInvoice
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const outgoingInvoiceModalShow = ref(false)
    const outgoingInvoiceModalTitle = ref(i18n.t('NewInvoice'))
    const currentOutgoingInvoice = ref({})
    const submitOutgoingInvoiceFormRef = ref(null)
    const isMounted = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(outgoingInvoiceModalShow, () => {
      if (outgoingInvoiceModalShow.value == false) {
        resetOutgoingInvoice()
      }
    })

    watch(currentOutgoingInvoice, () => {
      if (getRoute().name == 'Workflow view') {
        let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
        currentOutgoingInvoice.value.workflow = {
          id: workflow.id,
          opportunity: workflow.opportunity
        }

        if (
          !('id' in currentOutgoingInvoice.value) &&
          currentOutgoingInvoice.value.customerCompany == null &&
          currentOutgoingInvoice.value.customerIndividual == null
        ) {
          currentOutgoingInvoice.value.customerCompany = workflow.customerCompany
          currentOutgoingInvoice.value.customerIndividual = workflow.customerIndividual

          if (workflow.customerCompany != null && workflow.customerCompany.addresses.length) {
            currentOutgoingInvoice.value.billingAddress = workflow.customerCompany.addresses[0]
            currentOutgoingInvoice.value.deliveryAddress = workflow.customerCompany.addresses[0]
          }
        }
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchArticles,
      fetchBankAccounts,
      fetchUsers,
      fetchCompanies,
      fetchOutgoingInvoices,
      fetchPaymentMethods,
      fetchPaymentTerms,
      fetchVats,
      fetchWorkflows,
    } = useAPI()

    const {
      submitValidatedOutgoingInvoice,
      removeOutgoingInvoice,
      validateOutgoingInvoice,
      downloadOutgoingInvoice
    } = useOutgoingInvoices()

    const populateWorkflow = (outgoingInvoice) => {
      if (getRoute().name == 'Workflow view') {
        let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)

        outgoingInvoice.workflow = {
          id: workflow.id,
          opportunity: workflow.opportunity
        }
      }
    }

    const updateWorkflow = (updatedOutgoingInvoice) => {
      if (getRoute().name == 'Workflow view') {
        let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)

        if ('id' in updatedOutgoingInvoice) {
          let index = workflow.outgoingInvoices.findIndex(o => o.id == updatedOutgoingInvoice.id)
          workflow.outgoingInvoices.splice(index, 1, JSON.parse(JSON.stringify(updatedOutgoingInvoice)))
        } else if (updatedOutgoingInvoice != null) {
          workflow.outgoingInvoices.push(updatedOutgoingInvoice)
        } else {
          workflow.outgoingInvoices.splice(index, 1)
        }
      }
    }

    const addOutgoingInvoice = () => {
      outgoingInvoiceModalShow.value = true
    }

    const selectOutgoingInvoice = (outgoingInvoice) => {
      outgoingInvoiceModalTitle.value = i18n.t('EditInvoice')

      fetchOutgoingInvoices(outgoingInvoice.id).then((outgoingInvoice) => {
        currentOutgoingInvoice.value = JSON.parse(JSON.stringify(outgoingInvoice))
        populateWorkflow(currentOutgoingInvoice.value)

        outgoingInvoiceModalShow.value = true
      })
    }

    const tryCloseOutgoingInvoiceModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitOutgoingInvoiceFormRef.value.click()
      }
    }

    const submitValidatedOutgoingInvoiceLocal = () => {
      submitValidatedOutgoingInvoice(currentOutgoingInvoice.value)
        .then(response => {
          updateWorkflow(response.data)
          outgoingInvoiceModalShow.value = false

          if (getRoute().name == 'Workflow view') {
            let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
            response.data.workflow = response.data.workflow.id

            if ('id' in currentOutgoingInvoice.value) {
              let index = workflow.outgoingInvoices.findIndex(i => i.id == currentOutgoingInvoice.value.id)
              workflow.outgoingInvoices.splice(index, 1, JSON.parse(JSON.stringify(response.data)))
            } else {
              workflow.outgoingInvoices.push(response.data)
            }

          }
        })
    }

    const resetOutgoingInvoice = () => {
      currentOutgoingInvoice.value = JSON.parse(JSON.stringify(store.getters['outgoingInvoice/getEmptyOutgoingInvoice']))
    }

    const validateOutgoingInvoiceLocal = (outgoingInvoice) => {
      fetchOutgoingInvoices(outgoingInvoice.id).then((outgoingInvoice) => {
        validateOutgoingInvoice(outgoingInvoice)
          .then(response => {
            updateWorkflow(response.data)
            // if (getRoute().name == 'Workflow view') {
            //   let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
            //   // delete response.data.workflow
            //   response.data.workflow = response.data.workflow.id
            //   let index = workflow.outgoingInvoices.findIndex(i => i.id == currentOutgoingInvoice.value.id)
            //   workflow.outgoingInvoices.splice(index, 1, JSON.parse(JSON.stringify(response.data)))
            // }
          })
      })
    }

    const archivateOutgoingInvoiceLocal = (outgoingInvoice, isArchived) => {
      fetchOutgoingInvoices(outgoingInvoice.id).then((outgoingInvoice) => {
        currentOutgoingInvoice.value = outgoingInvoice
        populateWorkflow(currentOutgoingInvoice.value)
        currentOutgoingInvoice.value.isArchived = isArchived

        submitValidatedOutgoingInvoice(currentOutgoingInvoice.value)
          .then(response => {
            updateWorkflow(response.data)

            resetOutgoingInvoice()
          })
      })
    }

    const duplicateOutgoingInvoice = (outgoingInvoice) => {
      fetchOutgoingInvoices(outgoingInvoice.id).then((outgoingInvoice) => {
        currentOutgoingInvoice.value = outgoingInvoice
        populateWorkflow(currentOutgoingInvoice.value)
        delete currentOutgoingInvoice.value.billNumber
        delete currentOutgoingInvoice.value.customerPurchaseOrderNumber
        delete currentOutgoingInvoice.value.customerPurchaseOrderDate
        delete currentOutgoingInvoice.value.customerPurchaseOrder
        delete currentOutgoingInvoice.value.id
        delete currentOutgoingInvoice.value.logs

        outgoingInvoiceModalShow.value = true
      })
    }

    const removeOutgoingInvoiceLocal = (outgoingInvoice) => {
      removeOutgoingInvoice(outgoingInvoice)
        .then(response => {
          if (getRoute().name == 'OutgoingInvoice view') {
            router.push({ name: 'OutgoingInvoices' })
          } else if (getRoute().name == 'Workflow view') {
            updateWorkflow(response.data)
            // let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
            // workflow.outgoingInvoices = workflow.outgoingInvoices.filter(ii => ii.id != outgoingInvoice.id)
          }
        })
    }

    const getPreTaxAmounts = (outgoingInvoices) => {
      let preTaxAmount = 0
      outgoingInvoices.forEach(oi => preTaxAmount += parseFloat(oi.preTaxAmount))

      return preTaxAmount
    }

    const getTotalAmounts = (outgoingInvoices) => {
      let totalAmount = 0
      outgoingInvoices.forEach(oi => totalAmount += parseFloat(oi.totalAmount))

      return totalAmount
    }

    // const addPayment = (outgoingInvoice) => {
    //   console.log("1")
    // }

    const fetchForDetails = () => {
      fetchArticles()
      fetchBankAccounts()
      // fetchUsers()
      // fetchCompanies()
      fetchPaymentMethods()
      fetchPaymentTerms()
      fetchVats()
      // fetchWorkflows()
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (getRoute().name.startsWith('OutgoingInvoice')) {
      if (getRoute().params != null && ('outgoingInvoiceId' in getRoute().params)) {
        fetchOutgoingInvoices(getRoute().params.outgoingInvoiceId).then(() => {

          fetchUsers().then(() => {
            fetchCompanies().then(() => {
              fetchWorkflows().then(() => {
                fetchArticles().then(() => {
                  fetchForDetails()
                  isMounted.value = true
                })
              })
            })
          })

        })
      } else {
        fetchOutgoingInvoices().then(() => {
          fetchUsers()
          fetchCompanies()
          fetchWorkflows()
          fetchForDetails()
          isMounted.value = true
        })
      }

    }

    resetOutgoingInvoice()

    return {
      // Components
      capitalize,
      currency,
      displayPaymentStatus,

      // Data
      outgoingInvoiceModalShow,
      outgoingInvoiceModalTitle,
      currentOutgoingInvoice,
      submitOutgoingInvoiceFormRef,
      isMounted,

      // Computed

      // Methods
      addOutgoingInvoice,
      selectOutgoingInvoice,
      tryCloseOutgoingInvoiceModal,
      submitValidatedOutgoingInvoiceLocal,
      downloadOutgoingInvoice,
      validateOutgoingInvoiceLocal,
      archivateOutgoingInvoiceLocal,
      duplicateOutgoingInvoice,
      removeOutgoingInvoiceLocal,
      getPreTaxAmounts,
      getTotalAmounts
      // addPayment

    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteOutgoingInvoiceAlert (outgoingInvoice) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theInvoice') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeOutgoingInvoiceLocal(outgoingInvoice)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}