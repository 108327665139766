<template>
  <div>
    <b-card
        no-body
        class="position-relative"
        v-if="invoice"
    >
      <div
          v-if="invoice.billNumber == null"
          class="position-absolute h-100 w-100 overflow-hidden"
          style="z-index: 0"
      >
        <div class="d-flex h-100">
          <div class="m-auto">
            <p style="font-size:120px;transform:rotate(310deg);color:#f1f1f1;">{{ $t('DRAFT') }}</p>
          </div>
        </div>
      </div>

      <div style="z-index:1">
        <!-- Header -->
        <b-card-body class="invoice-padding pb-0">

          <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

            <!-- Header: Left Content -->
            <div v-if="myCompany">
              <div class="logo-wrapper">
                <b-img
                    :src="require('@/assets/images/logo/logo.png')"
                    alt="logo"
                />
                <h3 class="text-primary invoice-logo">
                  {{ myCompany.name }}
                </h3>
              </div>
              <!--              <p-->
              <!--                  v-if="myCompany.addresses.length"-->
              <!--                  class="card-text mb-25"-->
              <!--              >-->
              <!--                {{ myCompany.addresses[0]._display }}-->
              <!--              </p>-->
              <!--              <p-->
              <!--                  v-if="myCompany.addresses.length"-->
              <!--                  class="card-text mb-25"-->
              <!--              >-->
              <!--                {{ $store.getters['address/getCountry'](myCompany.addresses[0].country) }}-->
              <!--              </p>-->
              <!--              <p-->
              <!--                  v-if="myCompany.phone"-->
              <!--                  class="card-text mb-0"-->
              <!--              >-->
              <!--                {{ myCompany.phone }}-->
              <!--              </p>-->
            </div>
            <div v-else>
              <b-button
                  v-if="invoice._linkedOffer != null && invoice._linkedOffer.signedDocument != null"
                  v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                  variant="outline-warning"
                  size="sm"
                  @click="downloadByDocument(invoice._linkedOffer.signedDocument)"
              >
                <icon
                    icon="download"
                    class="mr-50"
                />
                <span class="align-middle">{{ capitalize($tc('order')) }}</span>
              </b-button>
            </div>

            <!-- Header: Right Content -->
            <div class="mt-md-0 mt-2">
              <h4
                  class="invoice-title"
                  :class="{'mb-1': invoice.billNumber != null}"
              >
                {{ capitalize($tc('invoice')) }}
                <span v-if="invoice.billNumber"
                      class="invoice-number">#{{ invoice.billNumber }}
                </span>
              </h4>
              <div v-if="invoice.billNumber && ('_paymentStatus' in invoice)"
                   class="text-right mb-2">
                <b-badge pill
                         :variant="paymentStatusLocal.variant"
                         class="badge-glow">{{ paymentStatusLocal.value }}
                </b-badge>
              </div>
              <div class="invoice-date-wrapper">
                <p class="invoice-date-title"
                   style="min-width: 200px;">
                  {{ $t('BillingDate:') }}
                </p>
                <p class="invoice-date">
                  {{ invoice.billingDate|moment('L') }}
                </p>
              </div>
              <div class="invoice-date-wrapper">
                <p class="invoice-date-title"
                   style="min-width: 200px;">
                  {{ $t('DueDate:') }}
                </p>
                <p class="invoice-date">
                  {{ invoice._dueDate|moment('L') }}
                </p>
              </div>
              <div class="invoice-date-wrapper">
                <p class="invoice-date-title text-nowrap"
                   style="min-width: 200px;">
                  {{ $t('PaymentMethod:') }}
                </p>
                <p class="invoice-date">
                  {{ $t(invoice.paymentMethod.name) }}
                </p>
              </div>
            </div>
          </div>
        </b-card-body>

        <!-- Spacer -->
        <hr class="invoice-spacing">

        <!-- Invoice Client & Payment Details -->
        <b-card-body
            class="invoice-padding pt-0"
        >
          <b-row class="invoice-spacing">

            <!-- Col: Invoice To -->
            <b-col
                cols="12"
                xl="6"
                class="p-0"
            >
              <h6 class="mb-1">
                <b>{{ capitalize($t('billingAddress')) }} :</b>
              </h6>
              <p class="card-text mb-25">
                {{ contact._display }}
              </p>
              <p class="card-text mb-25">
                {{ invoice.billingAddress._display }}
              </p>
              <p class="card-text mb-0">
                {{ getCountry(invoice.billingAddress.country) }}
              </p>
              <p v-if="contact.vat"
                 class="card-text mb-0">
                {{ $t('TVA') }} {{ contact.vat }}
              </p>
            </b-col>

            <!-- Col: Payment Details -->
            <b-col
                xl="6"
                cols="12"
                class="p-0"
            >
              <h6 class="mb-1">
                <b>{{ capitalize($t('deliveryAddress')) }} :</b>
              </h6>
              <p class="card-text mb-25">
                {{ contact._display }}
              </p>
              <p class="card-text mb-25">
                {{ invoice.deliveryAddress._display }}
              </p>
              <p class="card-text mb-0">
                {{ getCountry(invoice.deliveryAddress.country) }}
              </p>

              <p v-if="contact.vat"
                 class="card-text mb-0">
                &nbsp;
              </p>

            </b-col>
          </b-row>

          <b-row class="mt-3 mb-1">
            <b-col cols="12">
              <b>{{ invoice.title }}</b>
            </b-col>


            <b-col
                v-if="invoice.customerPurchaseOrderNumber || invoice.customerPurchaseOrderDate"
                cols="12"
            >
              <i>
                <span v-if="invoice.customerPurchaseOrderNumber">
                  {{ capitalize($t('sales order number', { number: invoice.customerPurchaseOrderNumber })) }}
                </span>

                <span v-if="invoice.customerPurchaseOrderDate">
                  {{ $t('signedE on', { date: displayDate(invoice.customerPurchaseOrderDate) }) }}
                </span>
              </i>
            </b-col>

            <b-col
                v-if="invoice._linkedOffer"
                cols="12"
            >
              <small>
                <i>
                  <span>
                    {{ capitalize($t('quoteNumber°')) }} {{ invoice._linkedOffer.billNumber }}
                  </span>

                  <span>
                    {{ $t('signed on', { date: displayDate(invoice._linkedOffer.signedDocumentDate) }) }}
                  </span>
                </i>
              </small>

              <small v-if="invoice._linkedOffer.signedDocumentNumber">
                <i>
                  - {{ $t('salesOrderNumber')}}{{ invoice._linkedOffer.signedDocumentNumber }}
                </i>
              </small>
            </b-col>

          </b-row>
        </b-card-body>

        <!-- Invoice Description: Table -->
        <b-table-lite
            responsive
            :items="invoice.products"
            :fields="productsTableHeader"
        >
          <template #cell(article)="data">
            <b-card-text class="font-weight-bold mb-25">
              {{ data.item.article.name }}
            </b-card-text>
            <b-card-text class="text-nowrap">
              <div v-html="data.item.article.description"></div>
            </b-card-text>
          </template>

          <template #cell(unitPrice)="data">
            <b-card-text class="">
              {{ currency(data.item.unitPrice) }}
            </b-card-text>
          </template>

          <template #cell(discount)="data">
            <b-card-text
                v-if="data.item.discount != null"
                class=""
            >
              <span v-if="data.item.isDiscountPercent">{{ percent(data.item.discount) }}</span>
              <span v-else>{{ currency(data.item.discount) }}</span>
            </b-card-text>
            <b-card-text
                v-else
                class=""
            >
              -
            </b-card-text>
          </template>

          <template #cell(preTaxAmount)="data">
            <b-card-text class="">
              {{ currency(data.item.preTaxAmount) }}
            </b-card-text>
          </template>
        </b-table-lite>

        <!-- Invoice Description: Total -->
        <b-card-body class="invoice-padding pb-0">
          <b-row>

            <!-- Col: Sales Persion -->
            <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
            >

              <h6
                  class="mb-50"
              >
                <b>{{ capitalize($tc('payment', 2)) }} :</b>
              </h6>
              <div v-if="invoice.paymentRelations.length == 0">
                <i>{{ $t('NoPayment') }}</i>
              </div>
              <b-table
                  v-if="invoice.paymentRelations.length"
                  small
                  :fields="paymentsTable.fields"
                  :items="paymentsTable.items"
              />

            </b-col>

            <!-- Col: Total -->
            <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
            >
              <div class="invoice-total-wrapper">

                <!--            Discount-->
                <div
                    v-if="invoice.discount"
                    class="invoice-total-item"
                >
                  <p class="invoice-total-title">
                    {{ $t('Discount:') }}
                  </p>
                  <p class="invoice-total-amount">
                    <span v-if="invoice.isDiscountPercent">{{ percent(invoice.discount) }}</span>
                    <span v-else>{{ currency(invoice.discount) }}</span>
                  </p>
                </div>

                <!--            Pre tax amount-->
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    {{ $t('PreTaxAmount:') }}
                  </p>
                  <p class="invoice-total-amount">
                    {{ currency(invoice.preTaxAmount) }}
                  </p>
                </div>

                <!--            Vat-->
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    {{ $t('VAT:') }}
                  </p>
                  <p class="invoice-total-amount">
                    {{ currency(invoice.vat) }}
                  </p>
                </div>

                <!--            Total amount-->
                <hr class="my-50">
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    {{ $t('TotalAmount:') }}
                  </p>
                  <p class="invoice-total-amount">
                    {{ currency(invoice.totalAmount) }}
                  </p>
                </div>

                <!--            Payments-->
                <hr v-if="invoice.paymentRelations.length"
                    class="my-50">
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    {{ $t('paymentsMade:') }}
                  </p>
                  <p class="invoice-total-amount">
                    {{ currency(-paymentsMade) }}
                  </p>
                </div>
                <hr v-if="invoice.paymentRelations.length"
                    class="my-50">
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    {{ $t('BalanceDue:') }}
                  </p>
                  <p class="invoice-total-amount">
                    {{ currency(parseFloat(invoice._balance)) }}
                    <!--                    {{ currency(parseFloat(invoice.totalAmount) + paymentsMade) }}-->
                  </p>
                </div>

              </div>
            </b-col>
          </b-row>
        </b-card-body>

        <!--        &lt;!&ndash; Spacer &ndash;&gt;-->
        <!--        <hr class="invoice-spacing">-->

        <!--        &lt;!&ndash; Note &ndash;&gt;-->
        <!--        <b-card-body class="invoice-padding pt-0">-->
        <!--          <span>-->
        <!--            <b>{{ $t('PaymentMethod:') }}</b>-->
        <!--          </span>-->
        <!--          <span>&nbsp;{{ $t(invoice.paymentMethod.name) }}</span>-->
        <!--        </b-card-body>-->
      </div>

    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency, percent } from '@/utils/filter'
import { displayPaymentStatus } from '../../utils/utils'
import { downloadByDocument } from '../../utils/utils'

import Ripple from 'vue-ripple-directive'
import useAPI from '@/utils/useAPI'
import i18n from '@/libs/i18n'
import moment from 'moment'

export default {
  directives: {
    Ripple,
  },
  components: {},
  props: {
    invoice: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const productsTableHeader = ref([
      {
        key: 'article',
        label: i18n.t('article')
      },
      {
        key: 'unitPrice',
        label: i18n.t('unitPrice')
      },
      {
        key: 'quantity',
        label: i18n.t('quantity')
      },
      {
        key: 'discount',
        label: i18n.t('discount')
      },
      {
        key: 'preTaxAmount',
        label: i18n.t('preTaxAmount')
      },

    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { myCompany, getCountry } = useAPI()

    const contact = computed(() => {
      if (props.invoice.customerCompany != null) {
        return props.invoice.customerCompany
      } else {
        return props.invoice.customerIndividual
      }
    })

    const paymentStatusLocal = computed(() => {
      return displayPaymentStatus(props.invoice._paymentStatus)
    })

    const paymentsMade = computed(() => {
      let paymentsMade = 0
      props.invoice.paymentRelations.forEach(pr => paymentsMade += parseFloat(pr.amount))

      return paymentsMade
    })

    const paymentsTable = computed(() => {
      let payments = []
      props.invoice.paymentRelations.forEach(pr => {
        let tmp = {}
        tmp[i18n.t('date')] = moment(pr.paiement.date).format('L')
        tmp[i18n.t('paymentMethod')] = i18n.t(pr.paiement.paymentMethod.name)
        tmp[i18n.t('amount')] = currency(pr.amount)
        payments.push(tmp)
      })

      return {
        fields: [i18n.t('date'), i18n.t('paymentMethod'), i18n.t('amount')],
        items: payments
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const displayDate = (date) => {
      return moment(date).format('LL')
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,
      percent,
      downloadByDocument,

      // Data
      productsTableHeader,

      // Computed
      myCompany,
      getCountry,
      contact,
      paymentStatusLocal,
      paymentsMade,
      paymentsTable,

      // Methods
      displayDate,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss"
       scoped>
@import "~@/assets/scss/base/pages/invoice.scss";
</style>


<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}

.invoice-preview .table th:first-child, .invoice-preview .table td:first-child, .invoice-edit .table th:first-child, .invoice-edit .table td:first-child, .invoice-add .table th:first-child, .invoice-add .table td:first-child {
  padding-left: 0.75rem!important;
}
</style>
