<template>
  <b-card
      class="card-transaction"
      no-body
  >
    <template #header>
      <div class="d-flex justify-content-between">
        <h4 class="mb-0">{{ capitalize($tc('payment', 2)) }} ({{ outgoingInvoice.bankOperations.length }})</h4>
        <!--        <span class="mb-0">{{ outgoingInvoice.bankOperations.length }} {{ $tc('payment', outgoingInvoice.bankOperations.length) }}</span>-->
      </div>
      <div
          :class="paid>=0?'text-success':'text-danger'"
      >
        {{ currency(paid) }}
      </div>
    </template>

    <b-card-body>
      <b-row>

        <b-col
            v-for="(payment, index) in outgoingInvoice.bankOperations"
            cols="12"
        >
          <payment-content
              :payment="payment"
              @edit="$emit('editPayment', payment)"
              @delete="$emit('deletePayment', payment)"
          />
        </b-col>

      </b-row>

      <!--      <card-document-->
      <!--          v-for="quote in documentQuotes"-->
      <!--          :document="quote"-->
      <!--          :isDeletable="false"-->
      <!--          @download="download(quote)"-->
      <!--          @click="download(quote)"-->
      <!--      />-->
    </b-card-body>


    <b-card-header
        v-if="outgoingInvoice._linkedOffer != null && outgoingInvoice._linkedOffer.signedDocument != null"
        class="pb-50">
      <b-card-title>{{ capitalize($tc('order')) }}</b-card-title>
    </b-card-header>
    <b-card-body
        v-if="outgoingInvoice._linkedOffer != null && outgoingInvoice._linkedOffer.signedDocument != null"
    >
      <card-document
          :document="outgoingInvoice._linkedOffer.signedDocument"
          :isDeletable="false"
          @download="downloadOrder(outgoingInvoice)"
          @click="downloadOrder(outgoingInvoice)"
      />
    </b-card-body>


    <b-card-header
        v-if="outgoingInvoice._linkedOffer == null && outgoingInvoice.customerPurchaseOrder != null"
        class="pb-50">
      <b-card-title>{{ capitalize($tc('order')) }}</b-card-title>
    </b-card-header>
    <b-card-body
        v-if="outgoingInvoice._linkedOffer == null && outgoingInvoice.customerPurchaseOrder != null"
    >
      <card-document
          :document="outgoingInvoice.customerPurchaseOrder"
          :isDeletable="false"
          @download="downloadPurchaseOrder(outgoingInvoice)"
          @click="downloadPurchaseOrder(outgoingInvoice)"
      />
    </b-card-body>

    <b-card-header
        v-if="outgoingInvoice._linkedOffer != null"
        class="pb-50">
      <b-card-title>{{ capitalize($tc('offer',1)) }}</b-card-title>
    </b-card-header>
    <b-card-body v-if="outgoingInvoice._linkedOffer != null">
      <!--      <card-document-->
      <!--          v-for="product in productInvoices"-->
      <!--          :document="{extension: 'pdf', name: product.outgoingInvoice.billNumber==null?capitalize($t('draft')):product.outgoingInvoice.billNumber}"-->
      <!--          :isDeletable="false"-->
      <!--          :isLinkable="true"-->
      <!--          @click="downloadOutgoingInvoice(product.outgoingInvoice)"-->
      <!--          @toRoute="$router.push({name: 'OutgoingInvoice view', params: {'outgoingInvoiceId': product.outgoingInvoice.id}})"-->
      <!--      />-->

            <card-document
                :document="{extension: 'pdf', name: outgoingInvoice._linkedOffer.quotes.slice(-1)[0].name }"
                :isDeletable="false"
                :isLinkable="true"
                @click="downloadOffer(outgoingInvoice)"
                @download="downloadOffer(outgoingInvoice)"
                @toRoute="$router.push({name: 'Offer view', params: {'offerId': outgoingInvoice._linkedOffer.id}})"
            />
    </b-card-body>
  </b-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../../utils/filter'

import PaymentContent from '../../../../components/card/components/PaymentContent'
import CardDocument from '../../../../components/card/Document'

export default {
  components: {
    PaymentContent,
    CardDocument,
  },
  props: {
    outgoingInvoice: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const paid = computed(() => {
      let output = 0

      props.outgoingInvoice.bankOperations.forEach(bo =>
          output += bo._balance
      )

      return output
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const downloadOffer = (outgoingInvoice) => {
      let link = document.createElement('a')
      link.setAttribute('target', '_blank')
      link.href = outgoingInvoice._linkedOffer.quotes.slice(-1)[0].url
      link.click()
    }

    const downloadOrder = (outgoingInvoice) => {
      let link = document.createElement('a')
      link.setAttribute('target', '_blank')
      link.href = outgoingInvoice._linkedOffer.signedDocument.url
      link.click()
    }

    const downloadPurchaseOrder = (outgoingInvoice) => {
      let link = document.createElement('a')
      link.setAttribute('target', '_blank')
      link.href = outgoingInvoice.customerPurchaseOrder.url
      link.click()
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data

      // Computed
      paid,

      // Methods
      downloadOffer,
      downloadOrder,
      downloadPurchaseOrder,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>